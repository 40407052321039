<div mat-dialog-content class="p-0">
  <section class="notification-banner">
    <div class="notification-banner__image-container">
      <img src="assets/images/new-notification.svg" class="notification-banner__image">
    </div>
  </section>


  <div class="container-fluid">




    <div class="row justify-content-center">
      <div class="col-md-12 col-12 ">
        <div class="text-center  mb-2 font-18" >
          <strong>{{ 'website.web_push_notification.request_permission_modal.title' | translate }}</strong>
        </div>

        <p>{{ 'website.web_push_notification.request_permission_modal.description' | translate }}</p>
        <ul>
          <li>{{ 'website.web_push_notification.request_permission_modal.feature_1' | translate }}</li>
          <li>{{ 'website.web_push_notification.request_permission_modal.feature_2' | translate }}</li>
        </ul>

        <p>{{ 'website.web_push_notification.request_permission_modal.benefit' | translate }}</p>

        <strong>{{ 'website.web_push_notification.request_permission_modal.how_to_enable' | translate }}</strong>

        <div *ngIf="permission === 'default'">
          <ul>
            <li>{{ 'website.web_push_notification.request_permission_modal.default_step_1' | translate }}</li>
            <li>{{ 'website.web_push_notification.request_permission_modal.default_step_2' | translate }}</li>
          </ul>
          <strong>{{ 'website.web_push_notification.request_permission_modal.default_cta' | translate }}</strong>
        </div>

        <div *ngIf="permission === 'denied'">
          <p>{{ 'website.web_push_notification.request_permission_modal.denied_instructions' | translate }}</p>
          <ul>
            <li>{{ 'website.web_push_notification.request_permission_modal.denied_step_1' | translate }}</li>
            <li>{{ 'website.web_push_notification.request_permission_modal.denied_step_2' | translate }}</li>
            <li>{{ 'website.web_push_notification.request_permission_modal.denied_step_3' | translate }}</li>
          </ul>
          <strong>{{ 'website.web_push_notification.request_permission_modal.denied_how_to_modify' | translate }}</strong>
          <ul>
            <li>{{ 'website.web_push_notification.request_permission_modal.denied_chrome' | translate }}</li>
            <li>{{ 'website.web_push_notification.request_permission_modal.denied_firefox' | translate }}</li>
            <li>{{ 'website.web_push_notification.request_permission_modal.denied_edge' | translate }}</li>
          </ul>
          <p>{{ 'website.web_push_notification.request_permission_modal.denied_reload_note' | translate }}</p>
        </div>

        <div *ngIf="permission === 'granted'">
          <p>{{ 'website.web_push_notification.request_permission_modal.granted_message' | translate }}</p>
        </div>

        <p>{{ 'website.web_push_notification.request_permission_modal.preferences_note' | translate }}</p>
      </div>
    </div>
  </div>
</div>

<mat-dialog-actions align="end">
  <button *ngIf="permission === 'denied'" mat-raised-button [color]="'primary'"  [mat-dialog-close]="false">
    {{ 'website.web_push_notification.request_permission_modal.modify_settings' | translate }}
  </button>

  <button *ngIf="permission === 'granted'" mat-raised-button [color]="'primary'" [mat-dialog-close]="true" cdkFocusInitial>
    {{ 'website.web_push_notification.request_permission_modal.ok' | translate }}
  </button>

  <button *ngIf="permission === 'default'" mat-raised-button [color]="'primary'" [mat-dialog-close]="false">
    {{ 'website.web_push_notification.request_permission_modal.reject' | translate }}
  </button>
  <button *ngIf="permission === 'default'" mat-raised-button [color]="'primary'" [mat-dialog-close]="true" cdkFocusInitial>
    {{ 'website.web_push_notification.request_permission_modal.accept' | translate }}
  </button>
</mat-dialog-actions>
